/* -Pagination links
---------------------------------------------------------------------------- */
.pagination {
  @include section-margin-top;

  display: flex;
  align-items: center;
  width: 100%;
}

.pagination__info {
  margin: 0 auto 0 0;
}

.pagination__prev ~ .pagination__next {
  margin-left: 30px;
}

@include media-max(rem(600px)) {
  .pagination__prev ~ .pagination__next {
    margin-left: 10px;
  }
  .pagination__prev,
  .pagination__next {
    a {
      @include button-small;
    }
  }
}
