/* - Block with single donation payment
-----------------------------------------------------------------------------*/
$form-parts-max-width: 400px;
.donation-block {
  @include section-margin;

  // Remove focus on anchor link
  &:focus {
    outline: 0;
  }

  // Payment iframe
  $payment-frame-padding: 10px;
  .checkout__section {
    margin-top: 30px;
  }
  .checkout__payment-frame {
    padding: $payment-frame-padding;
    background-color: #fff;

    &:empty {
      display: none;
    }
  }

  .section-block--center & {
    .checkout__section h2,
    .checkout__payment-frame {
      max-width: calc(400px + #{$payment-frame-padding});
      margin-right: auto;
      margin-left: auto;
    }
  }
}

.donation-block__form {
  .section-block--center & {
    text-align: center;
  }

  .radio-field {
    margin-bottom: 10px;

    label {
      display: block;
      position: relative;
      overflow: hidden;
    }
    .text {
      @include button-primary();

      width: 100%;
      max-width: $form-parts-max-width;
      border: 3px solid transparent;
    }
    input:checked ~ .text {
      @include button-primary-active-colors;

      border: 3px solid #000;
    }
    input {
      position: absolute;
      top: 0;
      right: 100%;
    }
  }

  .form-field--custom_amount {
    visibility: hidden;

    input {
      width: 100%;
      max-width: $form-parts-max-width;

      .section-block--center & {
        margin-left: auto;
        margin-right: auto;
      }
    }
    div[data-current-amount='custom'] & {
      visibility: visible;
    }
  }

  // Hide labels
  .form-field--amount .field-group-label,
  .form-field--custom_amount label {
    @include visuallyhidden;
  }

  // Amount field
  .form-field[data-currency] {
    display: block;

    &::after {
      display: none;
    }
  }
  .form-consent-text {
    font-size: rem(14px);
  }
  button[type='submit'] {
    @include button-yellow;
    @include button-swish;

    width: 100%;
    max-width: $form-parts-max-width;
  }
  // Hide error summary in footer since useless for only one field.
  .form-footer .field-error {
    display: none;
  }
  .form-field--amount .field-error {
    display: none;
  }
  .field-error {
    display: inline-block;
    padding: 0.25em 0.5em 0.25em 2em;
    width: 100%;
    max-width: $form-parts-max-width;
    border-radius: 2px;
    background-repeat: no-repeat;
    background-position: 0.5em 50%;
    background-size: em(24px) em(16px);
    color: #fff;
    text-align: left;
  }
  .form-field--error {
    .field-error {
      position: relative !important;
      background-color: $color-red;
      background-image: svg-url(
        '<svg width="24" height="16" viewBox="0 0 24 16"><path fill="#fff" d="M8 1.4l6.7 13.4H1.3L8 1.4zM8 0c-.3 0-.7.2-1 .7L.2 14.3c-.5.9-.1 1.7 1 1.7h13.6c1.1 0 1.5-.8 1-1.7L9 .7C8.7.2 8.3 0 8 0z"/><path fill="#fff" d="M9 13c0 .6-.4 1-1 1s-1-.4-1-1 .4-1 1-1 1 .4 1 1zM8 11c-.6 0-1-.4-1-1V7c0-.6.4-1 1-1s1 .4 1 1v3c0 .6-.4 1-1 1z"/></svg>'
      );
    }
    // Remove error styling on field.
    input {
      border-color: $color-form-field-border;
      background-color: #fff;
      color: $color-body-foreground;
      background-image: none;
      padding-right: 0;
    }
  }
}

.donation-block .form-errors {
  margin-top: 10px;
  background-color: #fff;
  color: $color-body-foreground;
}

// Disable form if payment is ongoing. Class is set with JS.
.customer-info-form--disabled {
  .radio-field {
    pointer-events: none;

    .text {
      cursor: pointer;
      opacity: 0.5;
    }
  }
}

// Success message after payment
.donation-block__message {
  @include section-margin;

  text-align: center;
}

// Different styling depending on section background color
.section-block--gray,
.section-block--red,
.section-block--pink,
.section-block--yellow,
.section-block--bg-image {
  .donation-block__form {
    button[type='submit'] {
      @include button-white-secondary;
    }
  }
}
.section-block--bg-image,
.section-block--yellow {
  .donation-block__form {
    .radio-field {
      input:checked ~ .text {
        border-color: #fff;
      }
    }
  }
}

.section-block--red {
  .donation-block__form {
    .radio-field {
      .text {
        @include button-white;
      }
      input:checked ~ .text {
        @include button-white-active-colors;
      }
    }
  }
}
