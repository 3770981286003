/* -Order product summary
---------------------------------------------------------------------------- */
.order-summary__items {
  margin-top: 10px;
  border-bottom: 1px solid $color-separator;
}
.order-summary__item {
  padding: 15px 0;
  border-top: 1px solid $color-separator;
}

.order-summary__item-info {
  display: flex;
}
.order-summary__item-product {
  flex-grow: 1;
  overflow: hidden;
}
.order-summary__item-title {
  margin-bottom: 0;
  font-size: rem(18px);
}
.order-summary__item-image {
  margin-right: 10px;

  img {
    max-width: 50px;
  }
}
.order-summary__item-price {
  margin-left: 10px;
  text-align: right;
  white-space: nowrap;
}

.order-summary__certificates {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 10px;
}
.order-summary__certificate {

  img {
    max-width: 60px;
  }
}
.order-summary__certificate--bg {
  padding: 5px 10px;
  display: flex;
  align-items: center;
  background: $color-gray-light;
  border-radius: 5px;
  line-height: 1.35;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);

  & + & {
    margin-top: 10px;
  }
  img {
    max-width: 40px;
    margin-right: 0.5em;
  }
}
.order-summary__certificate-title {
  font-size: 1em;
}
.order-summary__certificate-details {
  margin: 0;
  font-size: rem(14px);
}

// Used to display selected images for products with physical format
$certificate-grid-col-gutter: 10px;
.order-summary__certificates--grid {
  .order-summary__certificate__items {
    @include grid-container($certificate-grid-col-gutter, $certificate-grid-col-gutter);
    @include grid-pull-bottom($certificate-grid-col-gutter, $certificate-grid-col-gutter);

    width: 100%;
  }
  .order-summary__certificate {
    @include grid-item(1*0.1, $certificate-grid-col-gutter, $certificate-grid-col-gutter);
    @include grid-margin-bottom($certificate-grid-col-gutter, $certificate-grid-col-gutter);

    max-width: calc(60px + #{$certificate-grid-col-gutter})
  }
}


.order-summary__shipping-title {
  margin: 15px 0;
}
