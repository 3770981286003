/* -Text variations
-----------------------------------------------------------------------------*/
// Take care with the rules for lead-text and sub-text since they should
// support application directly (e.g. <p class="lead-text">Text</p>) and on
// a container (e.g. <div class="lead-text"><p>One</p><p>Two</p></div>).
// The rich-text and lead/sub-text classes can be set on the same container
// (e.g. <div class="rich-text lead-text"><p>One</p><p>Two</p></div>).

@mixin lead-text {
  @include type-size('lead');

  margin-bottom: rem($text-spacing);
  line-height: 1.4;
  font-weight: $font-weight-light;
}
@mixin lead-text-reset {
  margin-bottom: 0;
  font-weight: normal;
}

@mixin sub-text {
  @include type-size('sub');

  margin-bottom: rem($text-spacing);
}

.lead-text {
  @include lead-text;
}
.sub-text {
  @include sub-text;
}

.lead-text,
.sub-text {
  p,
  ol,
  ul {
    margin-bottom: rem($text-spacing);
  }
}

.rich-text {
  // Prevent double margins when lead or sub text is set on a rich text container.
  &.lead-text,
  &.sub-text {
    margin-bottom: 0;
  }
  // Remove bottom margins from the last text elements of the last rich text.
  &:last-child {
    p,
    ol,
    ul {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

// Inline text alongside things like icons
.text {
  vertical-align: middle;

  // Restore default alignment if it appears on its own
  &:only-child {
    vertical-align: baseline;
  }
}
