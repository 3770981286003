/* -A single product
-----------------------------------------------------------------------------*/
$product-single-row-breakpoint: rem(500px);
$product-columns-breakpoint: rem(800px);

.product {
  @include section-margin(2);

  @include media-min($product-columns-breakpoint) {
    display: flex;
  }
}

.product__image {
  flex-grow: 1;
  max-height: 300px;
  max-width: 300px;
  margin-bottom: 15px;
  border-radius: 5px;
  overflow: hidden;
  text-align: center;

  @include media-max(rem(400px)) {
    width: 100%;
    max-width: none;
    height: 250px;

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }
  @include media-min($product-columns-breakpoint) {
    width: 40%;
    max-height: 475px;
    max-width: 475px;
    margin-bottom: 0;
    margin-right: 40px;
  }
}

.product__text {
  max-width: $wrap-width-thin;

  a {
    color: inherit;
  }
}

.product__footer {
  margin-top: 40px;

  @include media-min(rem(1200px)) {
    .form-field--quantity {
      margin-left: 40px;
    }
  }
}

// Shared between detail and lists.
.product-category {
  margin-bottom: 20px;
  font-weight: $font-weight-regular;

  img {
    max-width: 22px;
    margin-right: 5px;
  }
}

/* ------------------ List with products in same category ------------------ */
.product + .product-list {
  @include section-padding-bottom;

  .product-list__title {
    margin-bottom: 40px;
  }
}
