/* -Search form
---------------------------------------------------------------------------- */
.search-form {
  display: inline-flex;

  input {
    width: 200px;
    margin-right: 5px;

    @include media-min(rem(500px)) {
      .search-page & {
        width: 350px;
      }
    }

    @include media-min(rem(800px)) {
      .search-page & {
        width: 400px;
      }
    }
  }
}
