/* -Link block with button style
---------------------------------------------------------------------------- */
.link-block {
  &:last-child {
    margin-bottom: 0;
  }
}

.link-block--button {
  a {
    @include button-primary;
  }
}

.link-block--button-swish {
  img {
    margin-right: 15px;
    max-width: 30px;
  }
}

// Sections
.section-block--yellow,
.section-block--bg-image {
  .link-block--button {
    a {
      @include button-white;
    }
  }
}
.section-block--red {
  .link-block--button {
    a {
      @include button-white-secondary;
    }
  }
}

// Blurbs
.blurb {
  .link-block--button-swish {
    img {
      margin-right: 10px;
      max-width: 20px;
    }
  }
}

.blurb__inner--bg-color-gray,
.blurb__inner--bg-color-white {
  .link-block--button {
    a {
      @include button-primary;
    }
  }
}
.blurb__inner--bg-color-yellow {
  .link-block--button {
    a {
      @include button-white;
    }
  }
}
.blurb__inner--bg-color-red {
  .link-block--button {
    a {
      @include button-white-secondary;
    }
  }
}
