/* -List with news card
---------------------------------------------------------------------------- */
$news-list-gutter: 40px;
$news-list-gutter-tight: $news-list-gutter * 0.5;

.news-list {
  @include section-padding;
  background-color: $color-gray-light;
}

.news-list__items {
  @include grid-container($news-list-gutter, $news-list-gutter-tight);
  @include grid-pull-bottom($news-list-gutter, $news-list-gutter-tight);
}

.news-list__item {
  @include grid-item(1, $news-list-gutter, $news-list-gutter-tight);
  @include grid-margin-bottom($news-list-gutter, $news-list-gutter-tight);

  @include media-min(rem(1100px)) {
    @include grid-item-size(1 * 0.5);
  }
}
