$cart-step-symbol-size: 30px;
$cart-step-symbol-border-size: 2px;
$cart-step-symbol-line-spacing: 5px;

.cart-steps {
  @include section-margin-bottom;

  position: relative;
  overflow: hidden;

  ol {
    display: flex;
    justify-content: space-between;
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

.cart-step {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 15%;
  text-align: center;

  &:first-child {
    flex-basis: 0;
    text-align: left;
  }
  &:last-child {
    flex-basis: 0;
    text-align: right;
  }
  a {
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}

.cart-step__inner {
  display: inline-block;
  vertical-align: top;
  text-align: center;
}

.cart-step__symbol {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: $cart-step-symbol-size;
  height: $cart-step-symbol-size;
  line-height: $cart-step-symbol-size - $cart-step-symbol-border-size * 2;
  margin-bottom: 5px;
  border: $cart-step-symbol-border-size solid;
  border-radius: 50%;
  background: #fff;
  // Cover the pseudo element lines.
  box-shadow: 0 0 0 $cart-step-symbol-line-spacing #fff;
  font-weight: bold;
  z-index: 1;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 100%;
    width: 1000px;
    height: 2px;
    margin-left: $cart-step-symbol-line-spacing + $cart-step-symbol-border-size;
    margin-top: -1px;
    background: #ccc;
    z-index: -1;
    // Prevent the line from 'passing' hovers and clicks to the link that can
    // wrap the symbol.
    pointer-events: none;
  }
  .cart-step--completed &::after {
    background: $color-blue;
  }
  // Cover the lines from the previous items
  .cart-step:last-child &::after {
    width: 100px;
    background: #fff;
  }
}
.cart-step__text {
  display: block;
  position: relative;
  // Ensure the text is always above the symbol.
  z-index: 2;

  @include media-max(rem(500px)) {
    font-size: rem(14px);
  }
}

.cart-step--completed {
  .cart-step__symbol {
    color: $color-blue;
  }
  // Unlinked
  span.cart-step__inner {
    color: rgba(0, 0, 0, 0.45);
  }
}
.cart-step--current {
  .cart-step__symbol {
    background: $color-blue;
    border-color: $color-blue;
    color: #fff;
  }
  .cart-step__text {
    font-weight: $font-weight-bold;
  }
}
.cart-step--upcoming {
  color: rgba(0, 0, 0, 0.3);

  .cart-step__text {
    color: rgba(0, 0, 0, 0.45);
  }
}

// Yuck... The current step has bold text which will slightly change the flex
// item dimensions, causing alignment differences depending on which step is
// active. Setting a min-width requires keeping track of all possible language
// strings and adjusting it in case the font size ever changes. It would also
// cause uneven spacing between the items.
// This solution is to have a hidden, always bold version of the text that
// forces the item to be its maximum size.
.cart-step__text--hack {
  font-weight: $font-weight-bold !important;
  visibility: hidden;
  height: 1px;
  overflow: hidden;
}
