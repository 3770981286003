/* -'Image and content' block
-----------------------------------------------------------------------------*/
$image-content-columns-breakpoint: 750px;
$image-content-gutter: 40px;

.image-content-block {
  @include section-margin;
}

.image-content-block__inner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-content-block__image {
  max-width: 200px;
  margin-bottom: 1.5em;
  border-radius: 50%;
  overflow: hidden;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

@include media-min(rem(500px)) {
  .image-content-block__image {
    max-width: 300px;
  }
}

// Go to two columns
@include media-min(rem($image-content-columns-breakpoint)) {
  .image-content-block__inner {
    flex-direction: row;
  }
  .image-content-block:nth-child(2n + 1) {
    .image-content-block__image {
      margin-right: $image-content-gutter;
    }
  }
  .image-content-block:nth-child(2n) {
    .image-content-block__inner {
      flex-direction: row-reverse;
    }
    .image-content-block__image {
      margin-left: $image-content-gutter;
    }
  }
  .image-content-block__image {
    flex-shrink: 0;
    margin-bottom: 0;
    width: 38%;
    max-width: 400px;
  }
  .image-content-block__content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

@include media-min(rem(1300px)) {
  .image-content-block__image {
    max-width: 480px;
  }
}
