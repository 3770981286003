/* -The main header area with logo and navigation
-----------------------------------------------------------------------------*/
$breakpoint-site-header-large: rem(1000px);
$header-nav-spacing-small: 20px;
$header-nav-spacing-large: 40px;
$header-padding-horizontal: 25px;
$header-padding-vertical: 10px;
$header-logo-image-width-large: 145px;
$header-logo-width-large: $header-logo-image-width-large +
  $header-padding-horizontal * 2;
$header-logo-padding-small: 10px;
$header-logo-width-small: 120px + $header-logo-padding-small * 2;
$header-logo-width-extra-small: 110px + $header-logo-padding-small * 2;

/* ------------------- General styles for all screens ------------------- */
.header-nav {
  a {
    color: inherit;
  }
}

/* ------------------- Secondary menu (topmost menu) ------------------- */

.site-header__top {
  display: flex;
  border-bottom: 1px solid $color-separator;
  background-color: $color-gray-light;
}
.header-nav--secondary {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 $header-padding-horizontal;

  font-size: 12px;

  a {
    padding: 5px;
  }
}

/* ------------------- Main area: logo and multiple menus ------------------- */
.site-header__main {
  display: flex;
  position: relative;
  // Prevent large shifting while the logo is loading.
  min-height: 70px;
  background-color: #fff;
  border-bottom: 1px solid $color-separator;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
}
.site-header__logo {
  display: flex;
  align-items: center;
  flex-shrink: 0;

  a {
    max-width: $header-logo-width-small;
    padding: $header-padding-vertical $header-logo-padding-small;

    img {
      width: $header-logo-image-width-large;
    }
  }
}
.site-header__main-menu {
  display: flex;
  flex-grow: 1;
}

.header-nav--primary {
  a {
    font-weight: $font-weight-bold;
  }
}

// Stretch menu item links to full height.
.header-nav--primary,
.header-nav--static {
  &,
  & > ul,
  & > ul > li,
  & > ul > li > a {
    display: flex;
  }
  & > ul > li > a {
    align-items: center;
    justify-content: center;
  }
}

.header-nav--static {
  margin: 0;
  padding: 0;
}
.static-nav-item {
  > a {
    flex-direction: column;

    .icon-container {
      display: inline-block;
      height: 20px;
      width: 20px;
      margin-bottom: 5px;
    }

    .icon {
      height: inherit;
      width: inherit;
    }
  }
}
.static-nav-item--monthly {
  a {
    background-color: $color-red;
    color: #fff;
    margin: 5px 0;
    padding: 0 10px;
    border-radius: 5px;

    &:hover,
    &:focus {
      background-color: darken($color-red, 10%);

      .text {
        border-bottom-color: currentColor;
      }
    }
  }
}

/* ------------------- Search ------------------- */
.static-nav-search-form {
  display: none;
  position: absolute;
  top: calc(100% + #{$header-padding-vertical});
  right: 0;
  padding: 15px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  z-index: $z-header-search;

  .search-open & {
    display: block;
  }
}

/* ------------------- Small screen menu toggle ------------------- */
.menu-toggle {
  position: relative;
  padding-right: 10px;
  padding-left: 40px;
  background: transparent;
  color: inherit;
  font-size: rem(12px);

  &:hover,
  &:focus {
    background: $color-gray-light !important;
  }
}
.menu-toggle-icon {
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  width: 20px;

  &,
  &::before,
  &::after {
    position: absolute;
    height: 3px;
    background-color: currentColor;
    transition: background-color 0.15s ease;
    border-radius: 5px;
  }
  &::before,
  &::after {
    content: '';
    display: block;
    left: 0;
    width: 22px;
    transition: transform 0.15s ease;
  }
  &::before {
    top: 0;
    transform: translateY(-8px);
  }
  &::after {
    bottom: 0;
    transform: translateY(8px);
  }
  .nav-open & {
    background-color: rgba(255, 255, 255, 0);

    &::before {
      transform: translateY(0) rotate(-225deg);
    }
    &::after {
      transform: translateY(0) rotate(225deg);
    }
  }
}

/* ------------------- Small screens only (toggled menu) ------------------- */
@include media-max(rem(500px)) {
  .static-nav-item {
    margin-left: 5px !important;
  }
  .site-header__logo {
    a {
      max-width: $header-logo-width-extra-small;
    }
  }
}
@include media-max($breakpoint-menu-toggle) {
  .header-nav--secondary {
    padding: 5px 5px;

    .menu-item--secondary {
      display: none;
    }
  }

  .site-header__main {
    min-height: 60px;
  }

  .site-header__main-menu {
    flex-direction: row-reverse;
    margin-left: auto;
    padding-right: 10px;
  }

  .header-nav--primary {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: #fff;
    z-index: $z-sticky-menu;

    .nav-open & {
      display: block !important;
    }

    // Un-stretch the items
    > ul,
    > ul > li,
    > ul > li > a {
      display: block;
    }
    > ul {
      border-bottom: 1px solid $color-separator;
    }
    li {
      display: block;
      border-top: 1px solid $color-separator;
    }
    a {
      padding: 10px 15px;
      color: $color-gray-dark;
      font-weight: $font-weight-bold;
      font-size: 18px;

      &:hover,
      &:focus {
        background-color: $color-gray-light;
        color: #000;
      }
    }

    .current-item,
    .current-item-ancestor {
      > a {
        background-color: $color-gray-light;
        color: #000;
      }
    }

    ul ul a {
      padding-left: 40px;
      font-size: rem(14px);
    }
  }

  .static-nav-item {
    margin-left: 20px;

    > a {
      min-width: 50px;

      .text {
        font-size: 10px;
      }
    }
  }

  .header-nav--sub {
    display: none;
  }
}

/* ------------------- Large screens only (no toggled menu) ------------------- */
@include media-min($breakpoint-menu-toggle + rem(1px)) {
  .menu-toggle {
    display: none;
  }

  .site-header {
    // Pretend the header isn't there for sticky on the child to work.
    // Unfortunately removes the header from the accessibility tree, but it
    // contains nav elements and the main content is in a main element, so
    // hopefully the header isn't too important.
    // https://adrianroselli.com/2018/05/display-contents-is-not-a-css-reset.html
    display: contents;
  }

  .header-nav--secondary {
    .menu-item--secondary,
    .header-lang-item {
      display: inline-block;
      margin-left: 20px;
    }
    .header-lang-item {
      border: 0;
    }
  }

  .site-header__main {
    position: sticky;
    top: 0;
    z-index: $z-sticky-menu;
    flex-wrap: wrap;

    .site-header--has-sub-menu & {
      border-bottom-color: $color-sub-nav;
    }
  }
  .site-header__logo {
    border-right: 1px solid $color-separator;

    a {
      max-width: $header-logo-width-large;
      padding: $header-padding-vertical $header-padding-horizontal;
    }
  }
  .site-header__main-menu {
    position: relative;
    padding-right: $header-padding-horizontal;
  }

  .header-nav {
    a {
      .text {
        display: inline-block;
        padding: 1px 0;
        border-bottom: 2px solid transparent;
      }
    }
    a:hover,
    a:focus,
    .current-item > a,
    .current-item-ancestor > a {
      .text {
        border-bottom-color: $color-separator;
      }
    }
  }

  .header-nav--sub {
    flex-basis: 100%;
    padding: 10px 0 10px ($header-logo-width-large + $header-nav-spacing-small);
    background-color: $color-sub-nav;

    li {
      display: inline-block;
      margin-right: $header-nav-spacing-small;
    }
    a {
      font-size: 18px;
      font-weight: $font-weight-regular;
      color: #fff;
    }
    a:hover,
    a:focus,
    .current-item > a,
    .current-item-ancestor > a {
      .text {
        border-bottom-color: currentColor;
      }
    }
  }

  .header-nav--primary {
    flex-grow: 1;

    .menu-item--secondary {
      display: none;
    }
    li {
      display: inline-block;
      position: relative;
      margin-left: 20px;
      border: 0;
    }

    // Sub menu for small screens
    li ul {
      display: none;
    }
  }

  .site-header--has-sub-menu .header-nav--primary {
    .current-item,
    .current-item-ancestor {
      &.has-children {
        &::before {
          position: absolute !important;
          content: '';
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          border: 10px solid transparent;
          border-bottom-color: $color-sub-nav;
        }
      }
    }
  }

  .static-nav-item {
    margin-left: 20px;

    > a {
      min-width: 40px;

      .text {
        font-size: 12px;
      }
    }
  }
}

@include media-min($breakpoint-site-header-large) {
  .header-nav--primary {
    li {
      margin-left: $header-nav-spacing-large;
    }
    a {
      font-size: 18px;
    }
  }
  .header-nav--sub {
    padding-left: ($header-logo-width-large + $header-nav-spacing-large);
  }
}
