/* -Blurb component - block of image, optional icon, heading, short text and a link
-----------------------------------------------------------------------------*/
@use "sass:math";

$blurb-gutter: 40px;
$blurb-breakpoint-two-cols: rem(700px);
$blurb-breakpoint-three-cols: rem(1100px);
$blurb-breakpoint-four-cols: rem(1350px);
$blurb-custom-wrap: rem(1130px);

/*-------------------- List of blurbs positioned directly after intro --------------------*/
.page-content > .blurb-list-wrap:first-child {
  .has-intro & {
    margin-top: -50px !important;
    .blurb-list {
      justify-content: center;
    }
    .blurb {
      z-index: 1;
    }
  }
}

/*-------------------- Blurb--------------------*/

.blurb__inner {
  display: flex;
  flex-direction: column;
  max-width: 350px;
  min-height: 100px;
  height: 100%;
  border-radius: 5px;
  background-color: $color-gray-light;
  // May be in a section with another text color, set explicitly
  color: $color-body-foreground;

  @include media-min(rem(500px)) {
    min-height: 220px;
  }
}

.blurb__image {
  height: 200px;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  overflow: hidden;

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
}

// Background colors
.blurb__inner--bg-color-gray {
  background-color: $color-gray-light;
}
.blurb__inner--bg-color-pink {
  background-color: $color-pink-light;
}
.blurb__inner--bg-color-white {
  background-color: #fff;
}

.blurb__inner--bg-color-yellow {
  background-color: $color-yellow;
}
.blurb__inner--bg-color-red {
  background-color: $color-red;
  color: #fff;
}

// Background image
.blurb__inner--bg-img {
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  .blurb__title,
  .blurb__text,
  .block-internal_link {
    color: rgba(255, 255, 255, 1);
    z-index: 2;
  }

  .link-block--button {
    a {
      @include button-white-secondary;
    }
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    height: 100%;
    width: 100%;
    top: 0;
    bottom: 0;
    background: linear-gradient(
      -180deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0) 100%
    );
  }
}

.blurb__content__wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: row-reverse;
  padding: 15px;
  @include media-min(rem(500px)) {
    padding: 25px;
  }
}

.blurb__content {
  display: flex;
  flex-direction: column;
  width: 100%;

  p {
    margin: 0;
  }
}
.blurb__text {
  flex-grow: 1;
}
.blurb__title {
  margin-bottom: 5px;
}

.link-block {
  .blurb & {
    margin-top: 20px;

    a {
      @include button-small;
    }
  }
}

.blurb__icon {
  flex-shrink: 0;
  margin-left: 10px;

  img {
    max-width: 50px;
  }
}

/*--------------- Product blurb ---------------*/
.blurb__image--product {
  height: 250px;
}
.blurb__form {
  padding: 0 15px 15px 15px;

  @include media-min(rem(500px)) {
    padding: 0 25px 25px 25px;
  }

  button {
    @include button-white-colors;
  }
}

/*--------------- List of blurbs ---------------*/

.blurb {
  @include grid-item(1, $blurb-gutter, $blurb-gutter * 0.5);
  max-width: 370px !important;
}

.blurb-list-wrap {
  @include section-margin;
  @include wrap-custom(rem(370px));
}

.blurb-list {
  @include grid-container($blurb-gutter, $blurb-gutter * 0.5);
  @include grid-pull-bottom($blurb-gutter, $blurb-gutter * 0.5);

  justify-content: center;

  .blurb {
    @include grid-margin-bottom($blurb-gutter, $blurb-gutter * 0.5);
  }
}

@include media-min($blurb-breakpoint-two-cols) {
  .blurb-list-wrap--cols-2,
  .blurb-list-wrap--cols-3,
  .blurb-list-wrap--cols-4 {
    .blurb-list {
      justify-content: flex-start;
    }
    @include wrap-custom(rem(740px));
    .blurb {
      @include grid-item-size(1*0.5);
    }
  }
}

@include media-min($blurb-breakpoint-three-cols) {
  .blurb-list-wrap--cols-3 {
    @include wrap-custom($blurb-custom-wrap);
    .blurb {
      @include grid-item-size(math.div(1, 3));
    }
  }
}

@include media-min($blurb-breakpoint-four-cols) {
  .blurb-list-wrap--cols-4 {
    @include wrap-width-wide;

    .blurb-list {
      justify-content: center;
    }
    .blurb {
      @include grid-item-size(1*0.25);
    }
  }
}
