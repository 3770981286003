// -------------------- Color definitions -------------------- //

$color-dark: #333;
$color-blue: #4a90e2;
$color-gray: #dddddd;
$color-gray-dark: #656565;
$color-gray-light: #f3f3f3;
$color-red: #e3222c;
$color-red-dark: #8a141a;
$color-pink-lightest: #fdf4f4;
$color-pink-light: #fce8e9;
$color-pink-dark: #f6bcbf;
$color-yellow: #f7bd2e;

// Lightest text color on white that meets WCAG AA accessibility requirement
$color-lightest-accessible-gray-on-white: #767676;

// -------------------- Semantic assignments -------------------- //
// When possible and logical, use colors through more meaningful
// "use case" names.

$color-separator: $color-gray;

$color-sub-nav: $color-red;

$color-basket: $color-yellow;

// Main body colors
$color-body-foreground: $color-dark;
$color-body-background: #fff;

// Section background colors
$color-section-gray-background: $color-gray-light;
$color-section-red-background: $color-red;
$color-section-yellow-background: $color-yellow;
$color-section-pink-background: $color-pink-lightest;
$color-section-separator: $color-pink-dark;

// Disabled text
$color-disabled: $color-lightest-accessible-gray-on-white;

// Brand things like selection color
$color-main: $color-blue;

// Links and main buttons
$color-action: $color-blue;
$color-action-foreground: #fff;

// Forms
$color-form-field-border: #ccc;

// Messages
@function get-hue-foreground($color) {
  @return change-color(
    $color: $color-body-foreground,
    $hue: hue($color),
    $saturation: 50%
  );
}
$message-color-error: #ffeeee;
$message-color-info: #e4eff7;
$message-color-success: #eaf2d9;
$message-color-warning: #fffad3;
$message-color-error-foreground: get-hue-foreground($message-color-error);
$message-color-info-foreground: get-hue-foreground($message-color-info);
$message-color-success-foreground: get-hue-foreground($message-color-success);
$message-color-warning-foreground: get-hue-foreground($message-color-warning);
$message-color-error-separator: darken($message-color-error, 8%);
$message-color-info-separator: darken($message-color-info, 10%);
$message-color-success-separator: darken($message-color-success, 16%);
$message-color-warning-separator: darken(
  desaturate($message-color-warning, 15%),
  16%
);

// Status
$color-error: #ed404b;
$color-error-foreground: #d71421;
$color-error-background: #fae8e6;
$color-success: #49ad5a;
$color-success-foreground: #337a3b;
$color-success-background: #e7fae6;
