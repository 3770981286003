/* -A single product card used in product list
-----------------------------------------------------------------------------*/
.product-list {
  .blurb {
    max-width: 350px;
  }
}

.product-card--hide {
  display: none;
}

.product-card__inner {
  position: relative;
}

.product-card__image {
  border-radius: 5px;
  overflow: hidden;

  a {
    transition: opacity 0.15s ease;

    &:hover,
    &:focus {
      opacity: 0.9;
    }
  }
}

.product-card__content {
  .product-card__image ~ & {
    margin-top: 10px;
    padding: 5px;

    @include media-min(rem(400px)) {
      margin-top: 20px;
    }
  }
}

.product-card__meta {
  display: flex;

  @include media-max(rem(400px)) {
    font-size: rem(14px);
  }
}

.product-card__title {
  font-family: inherit;
  font-size: inherit;

  a {
    color: inherit;
    text-decoration: none;
  }
}

.product-card__category {
  flex-shrink: 0;
  // Put it a little bit more inline with the title text.
  margin-top: 3px;
  margin-left: auto;

  img {
    max-width: 22px;
    vertical-align: top;
  }
}

.product-card__form {
  margin-top: 10px;

  .add-to-cart__price {
    margin-top: 5px;

    .price {
      line-height: 1.2;
      font-weight: bold;
      font-size: 24px;
    }
  }
}
