/* -Form block
---------------------------------------------------------------------------- */
.form-block {
  // Make space for absolutely positioned field error
  .form-field[data-currency] {
    margin-bottom: 2rem;
  }

  // Add a light gray background to text fields when in a section without a
  // color (i.e. a white section).
  .section-block--none & {
    input:not([type='radio']):not([type='checkbox']),
    textarea {
      &:not([aria-invalid='true']):not(:focus) {
        background-color: #fafafa;
      }
    }
  }

  // Move success message to the bottom. The fixed header makes the built in
  // scroll-into-view behavior less useful.
  form {
    position: relative;
  }
  .form-message-wrap:not(:empty) ~ .form-footer {
    margin-bottom: 4rem;
  }
  .form-success {
    position: absolute;
    top: 100%;
    width: 100%;
    margin: 10px 0 0;
  }
}

/* ------------------- Monthly donor registration form ------------------- */
.form-block--monthly-donor {
  h3 {
    margin-top: 1rem;
  }
  .radio-field {
    display: inline-block;
    margin-right: 1em;
  }
  .form-field--custom_monthly_amount {
    margin-top: 0;

    label {
      @include visuallyhidden;
    }
  }
}
