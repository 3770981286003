/* -The payment iframe section
---------------------------------------------------------------------------- */
.checkout__section {
  @include section-margin-top;
}

.checkout__payment {
  // Try do reduce layout shifting a bit.
  min-height: 150px;
}

.checkout__payment-frame {
  // The iframe content is 400px wide and centered which looks off, so limit
  // the container. The iframe content is responsive and should continue to
  // work even if it's changed from 400 in the future.
  max-width: 400px;
}

.checkout__payment-loading {
  margin: 0 0 10px;
  // For the spinner.
  font-size: 24px;
}
