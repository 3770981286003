/* -Search page
---------------------------------------------------------------------------- */
@use "sass:math";

.search-page__content {
  @include section-padding-top;
}

.search-page__header {
  @include section-margin-bottom;

  text-align: center;
}

.search-page__results {
  @include section-padding;

  min-height: 30vh;
  background-color: $color-gray-light;
}
.search-page__empty {
  font-size: rem(20px);
}

.search-page__results-list {
  @include grid-container;
  @include grid-pull-bottom;
}

/* ------------------- Page results ------------------- */
.search-page__page-item {
  @include grid-item(1);
  @include section-margin-bottom;

  @include media-min(rem(600px)) {
    @include grid-item-size(1 * 0.5);
  }
  @include media-min(rem(1100px)) {
    @include grid-item-size(math.div(1, 3));
  }
  @include media-min(rem(1400px)) {
    @include grid-item-size(1 * 0.25);
  }
}
