/* -Cart view
---------------------------------------------------------------------------- */

/* ------------------- Shared cart styles ------------------- */
.cart {
  @include section-padding;

  h1 {
    margin-bottom: rem(30px);
  }
}
.cart__form {
  .form-field-group-fields {
    min-width: 100%;
  }
  .form-fields.delete {
    visibility: hidden;
    overflow: hidden;
    height: 0;
    margin: 0;
    padding: 0;
    border: 0;
  }
  .form-footer {
    margin-top: rem(40px);

    .form-error-small {
      margin-top: 1em;
      text-align: center;
    }
  }
  .form-actions {
    display: flex;
    justify-content: space-between;
  }
  .field-group-label {
    font-weight: $font-weight-bold;
  }
  @include media-max(rem(550px)) {
    label {
      font-size: rem(14px);
    }
  }
}
.cart__form--products,
.cart__form--certificates {
  .form-actions {
    button:first-child {
      @include button-secondary-colors;
    }
  }
}

.cart-total {
  margin-top: 10px;
  text-align: right;

  span {
    font-size: rem(24px);
    font-weight: $font-weight-bold;
  }
}

/* ------------------- Cart products view ------------------- */
.cart__form--products {
  .formset {
    border-bottom: 1px solid $color-separator;
  }
}

/* ------------------- A single product row ------------------- */
$cart-row-padding: 20px;
$cart-row-remove-size: 40px;

.cart__form--products {
  .form-fields {
    padding: $cart-row-padding 0;
    border-top: 1px solid $color-separator;
    position: relative;
  }
}
.cart-form__remove {
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
  margin: 0 !important;

  label {
    @include button-white;

    width: $cart-row-remove-size;
    height: $cart-row-remove-size;
    line-height: $cart-row-remove-size;
    padding: 0;
    border-radius: 0;
  }
  input {
    position: absolute;
    top: 0;
    right: 110%;

    .show-focus &:focus ~ .text {
      outline: 3px dotted;
    }
  }
  .text {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
  }
  .icon {
    width: 20px;
    height: 20px;
  }
  .field-error {
    display: none !important;
  }
}
.product-row {
  display: flex;
}
.product-row__image {
  margin-right: 10px;

  img {
    max-width: 70px;
    vertical-align: top;
  }
}
.product-row__title {
  margin: 0;
  font-family: inherit;
  font-size: rem(20px);
  letter-spacing: 0;
}
.product-row__description {
  flex-grow: 1;

  // Not the best solution, but the field itself is visibly in an error state
  // and the message doesn't really add much. In this particular form the
  // error should be obvious.
  .field-error {
    @include visuallyhidden;
  }
  input {
    width: 4em;
    height: 2em;
  }
}
.product-row__price {
  padding-top: $cart-row-remove-size - $cart-row-padding + 10px;
  white-space: nowrap;
}

@include media-min(rem(550px)) {
  .product-row__image {
    img {
      max-width: 90px;
    }
  }
  .product-row__description {
    .form-field {
      display: inline-block;
      margin-right: 1em;
    }
    input {
      display: inline-block;
    }
  }
}
