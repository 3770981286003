/* -List of companies
-----------------------------------------------------------------------------*/
@use "sass:math";

$companies-gutter: 50px;
$companies-gutter-small: $companies-gutter * 0.5;

.companies-block {
  @include section-margin;
}
.companies-block__content {
  @include section-padding-bottom;

  text-align: center;
}

.companies-block__wrap--cols-1,
.companies-block__wrap--cols-2 {
  @include wrap-width-custom(rem(540px));
}
.companies-block__wrap--cols-3 {
  @include wrap-width-custom(rem(840px));
}
.companies-block__wrap--cols-4 {
  @include wrap-width-custom(rem(1000px));
}

.companies-block__list {
  @include grid-container($companies-gutter, $companies-gutter-small);
  @include grid-pull-bottom($companies-gutter, $companies-gutter-small);

  align-items: center;
}

.company {
  @include grid-margin-bottom($companies-gutter, $companies-gutter-small);

  .companies-block__list--cols-1 & {
    @include grid-item(1, $companies-gutter, $companies-gutter-small);
  }
  .companies-block__list--cols-2 & {
    @include grid-item(1*0.5, $companies-gutter, $companies-gutter-small);
  }
  .companies-block__list--cols-3 & {
    @include grid-item(1*0.5, $companies-gutter, $companies-gutter-small);

    @include media-min(rem(400px)) {
      @include grid-item-size(math.div(1, 3));
    }
  }
  .companies-block__list--cols-4 & {
    @include grid-item(1*0.5, $companies-gutter, $companies-gutter-small);

    @include media-min(rem(400px)) {
      @include grid-item-size(math.div(1, 3));
    }
    @include media-min(rem(600px)) {
      @include grid-item-size(1*0.25);
    }
  }
}

/* - Company
---------------------------------------------------------------------------- */
.company {
  text-align: center;

  img {
    max-height: 180px;
  }
  a:hover {
    img {
      opacity: 0.8;
    }
  }
}
.company__name {
  display: inline-block;
  font-size: rem(20px);
  font-weight: $font-weight-bold;
}
