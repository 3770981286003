/* -Section block
---------------------------------------------------------------------------- */
.section-block {
  @include section-padding;
}

.section-block__inner {
  > .block-content:first-child {
    @include section-margin-bottom;
  }
  > *:first-child {
    margin-top: 0 !important;
  }
  > *:last-child {
    margin-bottom: 0 !important;
  }
}

.section-block--center {
  .block-content {
    text-align: center;
  }
}

.section-block--yellow {
  background: $color-section-yellow-background;
}
.section-block--red {
  background: $color-section-red-background;
  color: #fff;

  button:not(.btn) {
    @include button-white-secondary-colors;
  }
}
.section-block--gray {
  background: $color-section-gray-background;
}
.section-block--pink {
  border-top: 1px solid $color-section-separator;
  border-bottom: 1px solid $color-section-separator;
  background: $color-section-pink-background;

  // Hide bottom border from previos pink section
  & + & {
    margin-top: -1px;
    border-top: 1px solid $color-section-pink-background;
  }
}

.page-content {
  .section-block--bg-image:only-child {
    min-height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .section-block--pink:last-child {
    border-bottom: none;
  }
}

.section-block--bg-image {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
}

.section-block--red,
.section-block--bg-image {
  a:link,
  a:visited,
  .field-error,
  .field-req {
    color: inherit;
  }
}
