/* -Main menu basket popout
---------------------------------------------------------------------------- */
$basket-items-fade-height: 15px;

.static-nav-item--basket {
  a[aria-expanded='true'] {
    position: relative;

    &::before {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      border: 10px solid transparent;
      border-bottom-color: $color-basket;
    }
  }
  // For positioning the number when the text is hidden
  .static-nav-item__content {
    display: inline-block;
    position: relative;
  }
}

.basket {
  display: none;
  position: absolute;
  top: 100%;
  right: 1vw;
  width: 400px;
  max-width: 100vw;
  padding: 20px;
  background: $color-basket;
  z-index: $z-basket;

  @include media-max($breakpoint-menu-toggle) {
    right: 0;
  }
}
.basket--open {
  display: block;
}

.basket__items {
  position: relative;

  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: $basket-items-fade-height;
    background: linear-gradient(to top, $color-basket, rgba($color-basket, 0));
    z-index: 1;
  }
  > ul {
    overflow-y: auto;
    position: relative;
    // Kind of a magic number, but seems to work well with lots of window sizes
    max-height: 50vh;

    @include media-max-height(rem(400px)) {
      max-height: 40vh;
    }
  }
}

@keyframes basket-count-added {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
.basket-icon {
  position: relative;
}
.basket-icon__count {
  display: block;
  position: absolute;
  top: -5px;
  right: -10px;
  min-width: 1.5em;
  height: 1.5em;
  line-height: 1.5em;
  padding: 0 em(5px);
  border-radius: 1em;
  background: $color-basket;
  font-size: rem(12px);
  text-align: center;
  pointer-events: none;
}
.basket-icon__count--new {
  animation: basket-count-added 0.5s;
}

.basket__item {
  // Override inline-block from generic nav styles
  display: flex !important;
  align-items: center;
  margin: 0 0 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid darken($color-basket, 15%);

  &:last-child {
    margin-bottom: $basket-items-fade-height;
  }
}
.basket__item-image {
  margin-right: 10px;

  img {
    max-width: 50px;
  }
}
.basket__item-info {
  flex-grow: 1;
}
.basket__item-title {
  margin: 0 0 3px;
  font-size: inherit;
  font-weight: normal;
}
.basket__item-price {
  margin-left: 20px;
  white-space: nowrap;
}

.basket__total {
  margin: 0 0 1em;
  text-align: right;

  .price {
    font-weight: $font-weight-bold;
  }
}
