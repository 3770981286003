/* - Block with latest news
---------------------------------------------------------------------------- */
.latest-news {
  @include section-padding;

  background-color: $color-gray-light;
}

.latest-news__content {
  @include section-margin-bottom;

  text-align: center;
}
