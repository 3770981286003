/* -The global footer area
-----------------------------------------------------------------------------*/

/* ------------------- Site footer top ------------------- */
@use "sass:math";

$footer-menu-font-size: rem(18px);
.site-footer__top {
  @include section-padding;

  border-top: 1px solid $color-pink-dark;
  background: $color-pink-lightest;
  color: $color-red-dark;

  .h-sectioning {
    margin-bottom: 10px;
  }

  a {
    color: inherit;
    text-decoration: none;
    font-size: $footer-menu-font-size;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}
.site-footer__top__inner {
  display: flex;
}
.site-footer__nav {
  white-space: nowrap;
}
.site-footer__logo {
  margin-left: auto;

  a {
    display: block;
    max-width: 70px;

    &:first-child {
      margin-bottom: 10px;
    }
  }
}

.site-footer__menu {
  margin-bottom: 30px;

  li {
    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }
}

.site-footer__menu-text {
  > * {
    margin-bottom: 5px;
    font-size: $footer-menu-font-size;
    line-height: inherit;
  }
}

.site-footer__menu-title {
  line-height: inherit;
}

@include media-min(rem(400px)) {
  .site-footer__logo a {
    max-width: 90px;
  }
}

@include media-min(rem(600px)) {
  .site-footer__nav-groups {
    @include grid-container;
    @include grid-pull-bottom;
  }
  .site-footer__menu {
    @include grid-item(1 * 0.5);
    @include grid-margin-bottom;
  }
  .site-footer__nav {
    padding-right: 50px;
  }

  .site-footer__logo a {
    min-width: 110px;
  }
}

@include media-min(rem(800px)) {
  .site-footer__menu {
    @include grid-item(math.div(1, 3));
  }
  .site-footer__logo a {
    min-width: 130px;
  }
}

@include media-min(rem(900px)) {
  .site-footer__logo {
    display: flex;
    flex-grow: 1;
    align-self: flex-start;
    justify-content: flex-end;

    a {
      flex-basis: 50%;
      min-width: 120px;

      &:first-child {
        margin-right: 10px;
        margin-bottom: 0;
      }
    }
  }
}

@include media-min(rem(1200px)) {
  .site-footer__menu {
    @include grid-item-auto-size;
  }
  .site-footer__logo {
    align-self: center;

    a {
      min-width: auto;
      max-width: 170px;

      &:first-child {
        margin-right: 20px;
      }
    }
  }
}

@include media-min(rem(1500px)) {
  .site-footer__nav-groups {
    @include grid-container-gutter-size(80px);
  }
  .site-footer__menu {
    @include grid-item-gutter-size(80px);
  }
}

/* ------------------- Site footer bottom ------------------- */
.site-footer__bottom {
  @include section-padding;

  color: $color-gray-dark;
}

.site-footer__bottom__text {
  max-width: 600px;
}

.site-footer__bottom__social,
.site-footer__logo-90 {
  margin-top: 30px;
}

.social__icons {
  margin-top: 10px;
}

.social__link {
  display: inline-block;
  margin-right: 25px;
  margin-bottom: 10px;
  text-decoration: none;
  align-self: flex-start;

  .icon,
  img {
    height: 30px;
    width: 30px;
    margin-right: 10px;
  }
  &:link,
  &:visited {
    color: inherit;
  }
  &:hover,
  &:focus {
    .text {
      text-decoration: underline;
    }
  }
}
.icon--facebook {
  color: #3c5a99;
}
.site-footer__logo-90 {
  max-width: 280px;
  text-align: right;
}

@include media-min(rem(900px)) {
  .site-footer__bottom {
    display: flex;
    font-size: rem(18px);
  }
  .site-footer__bottom__text {
    margin-right: 60px;
  }
  .site-footer__bottom__social {
    // Flexbox leaves a lot of space for some reason.
    max-width: 10em;
    margin-top: 0;
    margin-right: 20px;
  }
  .site-footer__logo-90 {
    margin-left: auto;
  }
}

@include media-min(rem(1200px)) {
  .site-footer__bottom__social {
    flex-shrink: 0;
    max-width: none;
  }
  .social__icons {
    display: flex;
  }
}

@include media-min(rem(1400px)) {
  .site-footer__bottom__text {
    margin-right: 100px;
  }
}
