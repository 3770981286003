/* -Card used for news, latest news block and search results
---------------------------------------------------------------------------- */
$page-card-horizontal-breakpoint: rem(550px);

.page-card {
  display: flex;
  position: relative;
}

.page-card__inner {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  overflow: hidden;
  flex-grow: 1;

  .page-card--horizontal & {
    @include media-min($page-card-horizontal-breakpoint) {
      flex-direction: row;
      min-height: 200px;
    }
  }
}

.page-card__image {
  transition: opacity 0.15s ease;
  height: 150px;

  @include media-min(rem(450px)) {
    height: 200px;
  }

  .page-card--horizontal & {
    @include media-min($page-card-horizontal-breakpoint) {
      flex: 1 0 40%;
    }
    @include media-min($page-card-horizontal-breakpoint + rem(100px)) {
      flex-basis: 50%;
    }
  }

  &:hover,
  &:focus {
    opacity: 0.9;
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.page-card__content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 20px;
  background: #fff;

  a {
    color: inherit;
  }

  .page-card--horizontal & {
    @include media-min($page-card-horizontal-breakpoint) {
      flex: 1 0 60%;
    }
    @include media-min($page-card-horizontal-breakpoint + rem(100px)) {
      flex-basis: 50%;
    }

    @include media-min(rem(700px)) {
      padding: 25px;
    }
  }
}
.page-card__title {
  margin-bottom: 10px;
}
.page-card__title--small {
  font-size: rem(20px) !important;
}

.page-card__text {
  @include type-size('sub');

  &:last-child {
    margin-bottom: 0;
  }
}

.page-card__tags {
  list-style: none;
  margin-bottom: 10px;
  margin-left: 0;
  a {
    color: inherit;
  }
  li {
    display: inline-block;
  }
  li:not(:last-child) {
    margin-right: em(5px);

    &::after {
      content: ', ';
    }
  }
}

.page-card__bottom {
  display: flex;
  margin-top: auto;

  .page-card__search-category {
    margin-left: auto;
    padding: 2px 10px;
    border-radius: 2px;
    background-color: $color-gray;
    font-weight: $font-weight-bold;
  }
  .publish-date,
  .page-card__search-category {
    align-self: flex-end;
    margin-bottom: 0;
  }
}

.page-card__search-category {
  .page-card--horizontal & {
    display: none;
  }
}
