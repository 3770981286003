/* - Newsletter form, positioned in footer
---------------------------------------------------------------------------- */
$newsletter-breakpoint: rem(850px);

.newsletter-form {
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: center;
  background-color: $color-red;
  color: #fff;

  form {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    width: 100%;
  }

  .form-errors-wrap,
  .form-message-wrap {
    position: absolute;
    bottom: 100%;
    max-width: 100%;
  }

  // Field styles
  .form-field--email {
    ::placeholder {
      color: $color-red-dark;
    }
    input {
      border-color: transparent;
      min-width: 250px;

      &:focus {
        border-color: desaturate($color-action, 20%);
      }
    }
    label {
      @include visuallyhidden;
    }
  }

  .form-fields {
    flex-grow: 1;
    max-width: 450px;
  }
  .form-field-group-fields {
    width: 100%;
  }
  .form-field,
  .form-footer {
    margin-bottom: 10px;
  }
  .form-footer {
    margin-left: 15px;
    pointer-events: none;
  }
  .form-actions {
    pointer-events: auto;

    button {
      @include button-white-secondary-colors;

      // Prevent shifting when the text is switched while sending.
      min-width: 6em;
    }
  }
  // Hide it visually since it can't be easily positioned and isn't very useful
  // anyway due to the small number of fields.
  .form-error-small {
    @include visuallyhidden;
  }

  .form-message-wrap,
  .form-errors-wrap {
    display: flex;
    flex-basis: 100%;
    justify-content: center;
  }
  .form-success,
  .form-errors {
    margin-bottom: 0;
    color: $color-body-foreground;
    background-color: #fff;
  }

  .form-field--honeypot {
    position: absolute;
    top: 100%;
    left: -$grid-gutter;

    @include when-grid-tight {
      left: -$grid-gutter-tight;
    }

    input {
      display: inline-block;
      width: 5em;
      margin-left: 0.25em;
    }
  }

  .field-error {
    display: none;
  }
  .form-field--error {
    .field-error {
      display: block;
      color: #fff;
    }
  }
}

.newsletter__title {
  flex-shrink: 0;
}

@include media-min($newsletter-breakpoint) {
  .newsletter-form__inner {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .newsletter-form {
    display: flex;
    align-items: center;

    form,
    .form-message-wrap,
    .form-errors-wrap {
      justify-content: flex-start;
    }
  }

  .newsletter__title {
    margin-right: 40px;
  }
}
