/* -Product list page
-----------------------------------------------------------------------------*/

// Intro
@use "sass:math";

.product-list-intro {
  @include section-margin;

  display: flex;
  flex-direction: column;
}
.product-list-intro__content {
  max-width: $wrap-width-thin;
}
.product-list-intro__image {
  text-align: center;
  max-width: 350px;
  align-self: center;

  img {
    max-height: 100px;
  }
}
.product-list-intro__caption {
  margin-top: 10px;
  font-size: 14px;
  @include media-min(rem(1000px)) {
    font-size: 16px;
  }
}

@include media-min(rem(800px)) {
  .product-list-intro {
    flex-direction: row;
  }
  .product-list-intro__content {
    margin-right: 80px;
  }

  .product-list-intro__image {
    margin-left: auto;
    img {
      max-height: 300px;
    }
  }
}

/* ------------------- Filtering ------------------- */
.product-list__filter {
  @include section-margin;

  display: flex;
  flex-wrap: wrap;
}
.product-list__filter-title {
  margin-right: 20px;
  margin-bottom: 5px;
  font-weight: $font-weight-bold;
}
.product-list__filter-item {
  position: relative;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 5px;
  overflow: hidden;

  @include media-min(rem(400px)) {
    margin-right: 15px;
  }

  input {
    position: absolute;
    top: 0;
    right: 100%;
  }
}
.product-list__filter-item-text {
  @include button-secondary;
  @include button-small;

  border-radius: 5px;
  font-weight: $font-weight-regular;
  white-space: nowrap;

  input:focus ~ & {
    @include button-secondary-active-colors;
  }
  input:checked ~ & {
    text-decoration: underline;
  }
  img {
    max-width: 22px;
    margin-right: 5px;
    margin-top: 2px;
    border: 2px solid #fff;
  }
}

/* ------------------- Product list ------------------- */
$product-list-gutter: 40px;
$product-list-gutter-small: $product-list-gutter * 0.5;

.product-list {
  @include section-margin;
}
.product-list__items {
  @include grid-container($product-list-gutter, $product-list-gutter-small);
  @include grid-pull-bottom($product-list-gutter, $product-list-gutter-small);
}

.product-list__item,
.product-list .blurb {
  @include grid-item(1, $product-list-gutter, $product-list-gutter-small);
  @include grid-margin-bottom($product-list-gutter, $product-list-gutter-small);

  @include media-min(rem(600px)) {
    @include grid-item-size(1 * 0.5, !important);
  }
  @include media-min(rem(1000px)) {
    @include grid-item-size(math.div(1, 3), !important);
  }
  @include media-min(rem(1300px)) {
    @include grid-item-size(1 * 0.25, !important);
  }
}
.product-list {
  .blurb,
  .blurb__inner {
    max-width: none !important;
  }
}