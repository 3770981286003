/* - Block with frequently asked questions
-----------------------------------------------------------------------------*/
$faq-block-gutter: 40px;
$faq-block-gutter-tight: $faq-block-gutter * 0.5;
$faq-block-column-breakpoint: rem(800px);

.faq-block {
  @include section-margin;
}

.faq-block__content {
  @include section-padding;
  max-width: $wrap-width-thin;
}

.faq-block__cols {
  @include grid-container($faq-block-gutter, $faq-block-gutter-tight);
}
.faq-block__col {
  @include grid-item(1, $faq-block-gutter, $faq-block-gutter-tight);

  @include media-min($faq-block-column-breakpoint) {
    @include grid-item-size(1*0.5);
  }
}

.faq-block__item {
  @include grid-margin-bottom($faq-block-gutter, $faq-block-gutter-tight);

  // May be in a section with another text color, set explicitly
  color: $color-body-foreground;
}

.faq-block__question {
  display: flex;
  border: 1px solid $color-separator;
  background: $color-gray-light;

  &.faq-block--open {
    margin-bottom: 0;
  }
}
.faq-block__question-text {
  flex-grow: 1;
  padding: 15px;
  margin: 0;
  line-height: 1.2;
  font-weight: $font-weight-bold;
  font-size: 18px;

  @include media-min($faq-block-column-breakpoint) {
    padding: 25px;
    font-size: 20px;
  }

  @include media-min(rem(1000px)) {
    font-size: 26px;
  }
}

.faq-block__toggle {
  position: relative;
  width: 60px;
  padding: 15px;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  border-left: 1px solid $color-separator;

  @include media-min($faq-block-column-breakpoint) {
    width: 80px;
    padding: 25px;
  }
  &:hover,
  &:focus {
    background: rgba(0, 0, 0, 0.05) !important;
  }
}
.faq-block__toggle-icon {
  width: 20px;
  height: 2px;

  @include media-min($faq-block-column-breakpoint) {
    width: 30px;
  }

  &,
  &::after {
    background-color: currentColor;
    transition: transform 0.15s ease;
  }

  &::after {
    position: absolute;
    content: '';
    bottom: 50%;
    right: 50%;
    height: 20px;
    width: 2px;
    transform: translate(50%, 50%);

    @include media-min($faq-block-column-breakpoint) {
      height: 30px;
    }
  }
  .faq-block__item--open & {
    transform: translateY(0) rotate(45deg);

    &::before {
      transform: translateY(0) rotate(-45deg);
    }
  }
}

.faq-block__answer {
  display: none;
  padding: 15px 20px;
  border: 1px solid $color-separator;
  border-top: 0;
  background-color: #fff;

  .faq-block__item--open & {
    display: block;
  }
  /* p {
    margin: 0;
  } */
}
