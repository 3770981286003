/* AUTOGENERATED, DO NOT EDIT MANUALLY */

/* signika-latin-600-normal*/
@font-face {
  font-family: 'Signika';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url('../fonts/signika-latin-600-normal.woff2') format('woff2'), url('../fonts/signika-latin-600-normal.woff') format('woff');
  
}

/* lato-latin-300-normal*/
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url('../fonts/lato-latin-300-normal.woff2') format('woff2'), url('../fonts/lato-latin-300-normal.woff') format('woff');
  
}

/* lato-latin-400-normal*/
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('../fonts/lato-latin-400-normal.woff2') format('woff2'), url('../fonts/lato-latin-400-normal.woff') format('woff');
  
}

/* lato-latin-700-normal*/
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url('../fonts/lato-latin-700-normal.woff2') format('woff2'), url('../fonts/lato-latin-700-normal.woff') format('woff');
  
}

/* lato-latin-400-italic*/
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url('../fonts/lato-latin-400-italic.woff2') format('woff2'), url('../fonts/lato-latin-400-italic.woff') format('woff');
  
}
