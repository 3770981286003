/* -Cart checkout view
---------------------------------------------------------------------------- */
.cart--checkout__section {
  & ~ & {
    @include section-margin-top;
  }
}

/* ------------------- The address form ------------------- */
.cart__form--checkout {
  .form-footer {
    margin-top: 0.5em;

    .form-error-small {
      text-align: left;
    }
  }
  // The user won't be able to complete a payment without JS so don't allow
  // creating an order that will be stuck in limbo.
  .no-js & button[type='submit'] {
    display: none !important;
  }
  .form-field--payment_method,
  .form-field--want_newsletter {
    @include grid-item-size(1);
  }
}

/* ------------------- The payment iframe section ------------------- */
.cart--checkout__payment {
  // Try do reduce layout shifting a bit.
  min-height: 150px;
}

.cart--checkout__payment-frame {
  // The iframe content is 400px wide and centered which looks off, so limit
  // the container. The iframe content is responsive and should continue to
  // work even if it's changed from 400 in the future.
  max-width: 400px;
}

.cart__payment-loading {
  margin: 0 0 10px;
  // For the spinner.
  font-size: 24px;
}
