/* Icons
---------------------------------------------------------------------------- */
/* GENERATED, DO NOT EDIT MANUALLY */

$icon-base-width: 16px;
$icon-base-height: 16px;
.icon {
  width: $icon-base-width;
  height: $icon-base-height;
  flex-grow: 0;
  flex-shrink: 0;
}

$icon-basket-width: 30px;
$icon-basket-height: 30px;
.icon--basket {
  width: $icon-basket-width;
  height: $icon-basket-height;
}

$icon-cross-width: 16px;
$icon-cross-height: 16px;
// Size matches base icon size, no rule generated

$icon-facebook-width: 16px;
$icon-facebook-height: 16px;
// Size matches base icon size, no rule generated

$icon-monthly-donor-width: 30px;
$icon-monthly-donor-height: 30px;
.icon--monthly-donor {
  width: $icon-monthly-donor-width;
  height: $icon-monthly-donor-height;
}

$icon-search-width: 30px;
$icon-search-height: 30px;
.icon--search {
  width: $icon-search-width;
  height: $icon-search-height;
}