/* -Cart certificates customization view
---------------------------------------------------------------------------- */

// A4 paper size in mm, match with Certificate.js.
@use "sass:math";

$a4-width: 210;
$a4-height: 297;
$preview-scaling: 3.5;
$preview-buffer: 50;

/* ------------------- A single certificate form ------------------- */
.cart__form--certificates {
  counter-reset: certificate-counter;

  .form-field-group--wrapper {
    > .form-field-group-fields {
      @include grid-container;

      > .form-field,
      > .form-field-group {
        @include grid-item(1);
      }
      .form-field--recipient,
      .form-field--sender {
        @include grid-item-size(1 * 0.5);
      }
    }
  }
  .form-fields {
    margin-top: $section-spacing * 0.5;
    margin-left: -$certificate-padding;
    margin-right: -$certificate-padding;
    padding: $certificate-padding;
    border-radius: 5px;
    background: $color-gray-light;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);

    @include when-page-margin-small {
      margin-left: -$certificate-padding-small;
      margin-right: -$certificate-padding-small;
      padding: $certificate-padding-small;
    }
  }
  .form-field--variant,
  div[data-single-image] {
    @include invisible(!important);
  }
}
.certificate-row__title {
  counter-increment: certificate-counter;

  &::before {
    content: attr(data-title-prefix) ' ' counter(certificate-counter) ': ';
  }
}

.certificate-image-radios {
  .radio-fields {
    @include grid-container(10px, 10px);
  }
  .radio-field {
    @include grid-item(math.div(1, 3), 10px, 10px);

    margin-bottom: 10px;

    @include media-min(rem(500px)) {
      @include grid-item-size(1 * 0.25);
    }
  }
  label {
    display: block;
    position: relative;
    overflow: hidden;

    .text {
      position: absolute;
      left: 0;
      bottom: 100%;
    }
  }
  img {
    border: 5px solid #ddd;
  }
  // Must use an extra element since pseudo elements don't work on replaced
  // elements like img.
  .image-radio-overlay {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 5px solid transparent;
  }
  label:hover .image-radio-overlay {
    border-color: $color-red;
  }
  // Show a different color for focus since the same color blends in with the
  // selected item.
  input:focus ~ .image-radio-overlay {
    border-color: $color-blue;
  }
  input {
    position: absolute;
    top: 0;
    right: 100%;

    &:checked ~ img {
      border-color: $color-red;
    }
  }
}

.form-field-group--physical_delivery {
  .field-group-label {
    margin-top: $form-field-spacing;
  }

  @include media-min(rem(550px)) {
    .form-field-group-fields {
      @include grid-container;
    }
    .form-field {
      @include grid-item(1);
    }
    .form-field--first_name,
    .form-field--last_name {
      @include grid-item-size(1 * 0.5);
    }
    .form-field--postal_code {
      @include grid-item-size($postal-code-field-width);
    }
    .form-field--city {
      @include grid-item-auto-size($shrink: 0);
    }
  }
}

// Show/hide delivery type field groups
.form-field-group--digital_delivery,
.form-field-group--physical_delivery {
  display: none;
}
div[data-current-delivery-method='digital']
  ~ .form-field-group--digital_delivery,
div[data-current-delivery-method='physical']
  ~ .form-field-group--physical_delivery {
  display: block;
}

.certificate-row__preview {
  margin-top: 1em;
}

/* ------------------- Preview lightbox ------------------- */
.certificate-preview {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  color: $color-body-foreground;
}
.certificate-preview-loading {
  position: absolute;
  top: 25%;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 1.25rem;
  font-style: italic;

  @media screen and (max-width: rem(600px)),
    screen and (max-height: rem(800px)) {
    font-size: 1.5rem;
  }
  @media screen and (max-width: rem(500px)),
    screen and (max-height: rem(700px)) {
    font-size: 2rem;
  }
}
.certificate-preview-inner {
  // Position the content with the 50/-50 trick to keep it centered even when
  // it's overflowing on smaller screens.
  position: absolute;
  left: 50%;
  transform: scale(0.5) translateX(-50%);
  transform-origin: 0 0;
  // Margin doesn't scale with the transform and padding is taken.
  border-top: ($preview-buffer * 0.5) * 1px solid transparent;
  background: #fff;
  // Stop the background from showing under the transparent border.
  background-clip: padding-box;
}
.certificate-preview-frame {
  position: relative;
  width: ($a4-width * $preview-scaling * 1px);
  height: ($a4-height * $preview-scaling * 1px);

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
