/* -Headings
-----------------------------------------------------------------------------*/
@mixin h {
  margin-bottom: 5px;
  line-height: 1.1;
  font-family: $font-stack-alt;
}
@mixin h-upper {
  text-transform: uppercase;
}
@mixin h-lower {
  text-transform: none;
}

@mixin h1 {
  @include type-size('h1');
  @include h-upper;
}
@mixin h2 {
  @include type-size('h2');
  @include h-upper;
}
@mixin h3 {
  @include type-size('h3');
  @include h-lower;
}
@mixin h4 {
  @include type-size('h4');
  @include h-lower;
}
@mixin h5 {
  @include type-size('h5');
  @include h-lower;
}
@mixin h6 {
  @include type-size('h6');
  @include h-lower;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @include h;

  a:link,
  a:visited {
    color: inherit;
    text-decoration: none;
  }
  a:hover,
  a:focus {
    text-decoration: underline;
  }
}

h1,
.h1 {
  @include h1;
}
h2,
.h2 {
  @include h2;
}
h3,
.h3 {
  @include h3;
}
h4,
.h4 {
  @include h4;
}
h5,
.h5 {
  @include h5;
}
h6,
.h6 {
  @include h6;
}

.h2-small {
  @include type-size('h2-small');
  @include h-lower;
}

.h-upper {
  @include h-upper;
}

.rich-text {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    &:first-child {
      margin-top: 0 !important;
    }
  }
}
