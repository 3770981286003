/* -Django messages
---------------------------------------------------------------------------- */
@keyframes message-enter {
  from {
    transform: translateY(-10px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.messages {
  width: 100%;
  margin: 0;
  list-style: none;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);

  // Only use fixed if remove button is available, which requires JS.
  // Otherwise it will cover the header without a way to get rid of it.
  .js & {
    position: fixed;
    top: 0;
    left: 0;
    z-index: $z-messages;
  }
  @include media-max(rem(500px)) {
    font-size: rem(14px);
  }
  li {
    display: flex;
    align-items: center;
    animation: message-enter 0.2s ease;
  }

  // prettier-ignore
  $message-types:
    'error' $message-color-error $message-color-error-foreground $message-color-error-separator,
    'info' $message-color-info $message-color-info-foreground $message-color-info-separator,
    'success' $message-color-success $message-color-success-foreground $message-color-success-separator,
    'warning' $message-color-warning $message-color-warning-foreground $message-color-warning-separator;
  @each $type, $background, $foreground, $border in $message-types {
    .#{$type} {
      border-bottom: 1px solid $border;
      background-color: $background;
      color: $color-body-foreground;
      color: $foreground;

      .message__close:hover,
      .message__close:focus {
        background-color: $border;
      }
    }
  }
}

.message__text {
  padding: 0.75em 1em;
  flex-grow: 1;
}

.message__close {
  align-self: stretch;
  height: auto;
  padding: 0 15px;
  border-radius: 0;
  background: transparent;
  color: inherit;

  .icon {
    width: 22px;
    height: 22px;
    margin: 0 !important;
  }
}
