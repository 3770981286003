$wrap-width: rem(1260px);
$wrap-width-thin: rem(740px);
$wrap-width-wide: rem(1520px);

// Keep $page-margin above $certificate-padding.
$page-margin: rem(60px);
$page-margin-small: rem(25px);
$section-spacing: 60px;
$section-spacing-small: 30px;

// Keep below $page-margin.
$certificate-padding: rem(30px);
$certificate-padding-small: rem(15px);

// Form elements
// Using em instead of rem so that dimensions adapt to local font size changes
$form-control-base-height: 45px;
$form-control-font-size: 18px;
$form-control-radius: 2px;
$form-field-padding: em(5px, $form-control-font-size);
$form-field-spacing: 10px;
$form-control-height: em($form-control-base-height, $form-control-font-size);
$button-font-size: 22px;
$button-height: em($form-control-base-height, $button-font-size);
$button-radius: 5px;

$postal-code-field-width: 13em;

$button-font-size-small: 16px;
$button-height-small: em(30px, $button-font-size-small);

/*-------------------- Common breakpoints --------------------*/
// Most breakpoints should be isolated to what they affect and not be some
// arbitrary values, but global changes like text size may require adjustment
// to details in some instances and should therefore be a defined variable.

$breakpoint-menu-toggle: rem(850px);

// Tighten layout
$breakpoint-grid-tight: rem(700px);
$breakpoint-page-margin-small: rem(650px);
