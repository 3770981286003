// Typography settings and mixins for common font sizes.
//
// Sizes are defined in a list of maps, where each map has a name (preferably
// a size name like 'small', 'medium' etc.), a breakpoint from which min-width
// media queries are generated, and any arbitrary properties for text sizes.
// These are then used via the type-size mixin.

// Unit conversion
// ----------------------------------------------------------------------------

// The base font size should be kept at 16px as a definition of the browser
// default and used as a base for calculation. To globally increase the font
// size on the site, add a rule to a containing element instead of changing
// this.
@use "sass:math";

$_base-font-size: 16px;

/// Convert a px value to em.
///
/// @param {number} $px - Pixel value to convert.
/// @param {number} [$base] - Base font size, i.e. the pixel value that equals
///   1em. Defaults to the default font size.
/// @return {number} - Em value.
@function em($px, $base: $_base-font-size) {
  @if unit($px) == 'em' {
    @return $px;
  }

  @if unit($base) == 'em' {
    $base: px($base);
  }

  @return strip-unit(math.div($px, $base)) * 1em;
}

/// Convert a em value to px.
///
/// @param {number} $em - Em value to convert.
/// @param {number} [$base] - Base font size, i.e. the pixel value that equals
///   1em. Defaults to the default font size.
/// @return {number} - Px value.
@function px($em, $base: $_base-font-size) {
  @if unit($em) == 'px' {
    @return $em;
  }

  @if unit($base) == 'px' {
    $base: em($base);
  }

  @return round(strip-unit($em * $base * $_base-font-size)) * 1px;
}

/// Convert a px or em value to rem.
///
/// @param {number} $px - Pixel or em value to convert.
/// @return {number} - Rem value.
@function rem($value) {
  @if unit($value) == 'px' {
    @return math.div($value, $_base-font-size) * 1rem;
  } @else if unit($value) == 'em' {
    @return math.div($value, 1em) * 1rem;
  } @else {
    @error 'Expected px or em value, got `#{unit($value)}`';
  }
}

// Genereal typography definitions
// ----------------------------------------------------------------------------

// Font weights
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-bold: 700;

// Font stacks
$font-stack-main: 'Lato', sans-serif;
$font-stack-alt: 'Signika', sans-serif;
$font-stack-mono: Consolas, 'Andale Mono', 'Lucida Console', monospace;

// The alt font doesn't have a regular weight and may not be applied without
// a valid weight specified. Set the alt stack via the mixin to ensure it's
// done properly.
$valid-alt-font-weights: (
  bold: $font-weight-bold,
);
@mixin alt-font($weight) {
  font-family: $font-stack-alt;
  font-weight: map-get-strict($valid-alt-font-weights, $weight);
}

// Paragraph margins and such, one for pixels and one for em. The former should
// likely only be relevant for calculations.
$base-text-spacing: $_base-font-size * 1.5;
$text-spacing: em($base-text-spacing);

// $text-spacing without 'em'
$base-line-height: math.div($text-spacing, 1em);

// Type sizes
// ----------------------------------------------------------------------------

// Definitions from smallest to largest. The smallest size must define all
// available values. Anything not specified in other sizes stays on the
// previous size, e.g. small h6 at 16px will stay at 16px unless specified
// in another map.
//
// Available values:
//
// - name: REQUIRED, breakpoint name.
// - breakpoint: REQUIRED for all but the smallest, media query min-width.
// - h1 to h6: heading sizes.
// - lead: lead (larger) text size.
// - sub: sub (smaller) text size.
$type-sizes: (
  (
    name: 'small',
    h1: 38px,
    h2: 28px,
    h2-small: 24px,
    h3: 20px,
    h4: 18px,
    h5: $_base-font-size,
    h6: $_base-font-size,
    lead: 20px,
    sub: 14px,
  ),
  (
    name: 'medium',
    breakpoint: 700px,
    h1: 48px,
    h2: 36px,
    h2-small: 32px,
    h3: 24px,
    h4: 20px,
    lead: 24px,
  ),
  (
    name: 'large',
    breakpoint: 1200px,
    h1: 64px,
    h2: 48px,
    h2-small: 44px,
    h3: 26px,
  )
);

// Validate map structure
@for $i from 1 through length($type-sizes) {
  $_size-data: nth($type-sizes, $i);
  $_name: map-get($_size-data, 'name');
  @if not $_name {
    @error "Missing 'name' in type-sizes map #{$_size-data}";
  }
  $_breakpoint: map-get($_size-data, 'breakpoint');
  @if $i == 1 and $_breakpoint {
    @error "The '#{$_name}' type size is the default and shouldn't have a breakpoint";
  } @else if $i != 1 and not $_breakpoint {
    @error "The '#{$_name}' type size is missing a breakpoint";
  }
}

/// Check if the specified type breakpoint name is the default one.
///
/// @param {string} $breakpoint-name - Breakpoint name to check.
/// @return {boolean} - True if default.
@function is-default-type-breakpoint($breakpoint-name) {
  @if map-get-strict(nth($type-sizes, 1), 'name') == $breakpoint-name {
    @return true;
  }
  @return false;
}

/// Get type size data for a breakpoint.
///
/// @param {string} $breakpoint-name - Name of breakpoint to get data for.
/// @return {Map} - Size data.
@function get-type-size-data($breakpoint-name) {
  @for $i from 1 through length($type-sizes) {
    $size-data: nth($type-sizes, $i);
    @if map-get-strict($size-data, 'name') == $breakpoint-name {
      @return $size-data;
    }
  }
  @error "There is no type size named '#{$breakpoint-name}'";
}

/// Add a rule inside a min-width media query for the specified breakpoint.
///
/// @param {string} $breakpoint-name - Name of breakpoint to use.
/// @example scss - Assuming sizes 'small' and 'medium'.
///   @include when-type-size('medium') {
///     h1 { /* Rules */ }
///   }
///   // Equivalent to
///   @media screen and (min-width: <medium-breakpoint>) {
///     h1 { /* Rules */ }
///   }
@mixin when-type-size($breakpoint-name) {
  @if is-default-type-breakpoint($breakpoint-name) {
    @error "The '#{$breakpoint-name}' size is the default and doesn't need a media query";
  } @else {
    $size-data: get-type-size-data($breakpoint-name);
    $breakpoint: map-get-strict($size-data, 'breakpoint');
    @include media-min(rem($breakpoint)) {
      @content;
    }
  }
}

/// Add a font-size rule for every breakpoint with a size for that name defined.
///
/// @param {string} $size-name - Name of type size to use.
/// @param {string} $important - Pass !important to use.
/// @example scss - Assuming sizes 'small' and 'medium'.
///   h1 { @include type-size('h1'); }
///   // Equivalent to
///   h1 { font-size: <small-size>; }
///   @media screen and (min-width: <medium-breakpoint>) {
///     h1 { font-size: <medium-size>; }
///   }
@mixin type-size($size-name, $important: null) {
  @each $size-data in $type-sizes {
    $breakpoint-name: map-get-strict($size-data, 'name');
    // The default size is also the smallest size so no breakpoint needed.
    // Strict getting so that there is always a base size.
    @if is-default-type-breakpoint($breakpoint-name) {
      font-size: rem(map-get-strict($size-data, $size-name)) $important;
    } @else {
      $breakpoint: map-get-strict($size-data, 'breakpoint');
      $value: map-get($size-data, $size-name);
      @if $value {
        @include media-min(rem($breakpoint)) {
          font-size: rem($value) $important;
        }
      }
    }
  }
}
