/* -Icon and text component - block of icon, heading and text
-----------------------------------------------------------------------------*/
@use "sass:math";

.icon-and-text {
  text-align: center;
}

.icon-and-text__inner {
  position: relative;
}

.icon-and-text__icon {
  margin-bottom: 10px;

  img {
    max-height: 60px;
  }

  @include media-min(rem(500px)) {
    margin-bottom: 15px;

    img {
      max-height: none;
    }
  }
}
.icon-and-text__title {
  margin-bottom: 5px;
}
.icon-and-text__text {
  font-size: rem(12px);

  @include media-min(rem(500px)) {
    font-size: rem(14px);
  }
}

.overlay-link {
  &:hover,
  &:focus {
    ~ .icon-and-text__content .icon-and-text__title {
      text-decoration: underline;
    }
  }
}

/*--------------- List of icon blocks ---------------*/
.icons-list-wrap {
  @include section-margin;
}

.icons-list {
  @include grid-container;
  @include grid-pull-bottom;

  &.icons-list--cols-1 {
    justify-content: center;

    .icon-and-text {
      max-width: 400px;
    }
  }
}

.icon-and-text {
  @include grid-item(1*0.5);
  @include grid-margin-bottom;

  .icons-list--cols-1 & {
    @include grid-item(1);
  }

  .icons-list--cols-3 &,
  .icons-list--cols-4 &,
  .icons-list--cols-5 & {
    @include media-min(rem(750px)) {
      @include grid-item-size(math.div(1, 3));
    }
  }
  .icons-list--cols-4 &,
  .icons-list--cols-5 & {
    @include media-min(rem(1000px)) {
      @include grid-item-size(1*0.25);
    }
  }

  .icons-list--cols-5 & {
    @include media-min(rem(1300px)) {
      @include grid-item-size(1*0.2);
    }
  }
}
