/* -A single news article
---------------------------------------------------------------------------- */
.news-detail__body {
  @include section-margin;
}

.news-detail__image {
  @include section-margin(1 * 0.5);
}

.news-detail__meta {
  @include section-margin-top(1 * 0.5);
}

@include media-min(rem(800px)) {
  .news-detail__content {
    font-size: rem(18px);
  }
}

.news-detail__meta {
  @include section-padding-top(0.5);
  @include section-margin-top(0.5);

  display: flex;
  border-top: 1px solid $color-separator;

  &:empty {
    display: none;
  }
}
.news-detail__tags {
  list-style: none;
  margin: 0;

  li {
    display: inline-block;
    margin: 0 1.5em 1.5em 0;
    a {
      color: inherit;
    }
  }
}
