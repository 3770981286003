/* -Lightbox
-----------------------------------------------------------------------------*/
// Using the `a11y-dialog` library, based on their example page styles but
// without support for native <dialog> element since it's not ready yet
// (as of spring 2019).

$lightbox-z-index: 500;

.lightbox-open {
  &,
  body {
    overflow: hidden;
  }
}

.lightbox {
  color: #fff;

  &[aria-hidden='true'] {
    display: none;
  }
}

.lightbox,
.lightbox-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $lightbox-z-index;
}
.lightbox-overlay {
  background-color: rgba(0, 0, 0, 0.65);
  z-index: $lightbox-z-index + 1;
}

.lightbox-body {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 100vh;
  z-index: $lightbox-z-index + 2;
}

.lightbox-content-wrap {
  margin: 0 auto;
}

// Scrollable content
.lightbox--scrollable {
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

/* ------------------- Buttons ------------------- */
.lightbox-button {
  display: block;
  position: absolute;
  padding: 0;
  border: 0;
  background: none !important;
  color: #fff;

  svg {
    display: block;
    position: absolute;
    fill: currentColor;
    // Shadow around the rendered shape for light images
    filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.5));
  }
  &:not([disabled]) {
    &:hover,
    &:focus {
      color: #1b96e4;
    }
  }
}

// Prev/next
.lightbox-nav {
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 80px;
  z-index: $lightbox-z-index + 3;

  svg {
    width: 18px;
    height: 30px;
    top: 50%;
    transform: translateY(-50%);
  }
}
.lightbox-nav--prev {
  left: 0;

  svg {
    left: 20px;
  }
}
.lightbox-nav--next {
  right: 0;

  svg {
    right: 20px;
  }
}
@media screen and (max-width: 800px) {
  .lightbox-nav {
    top: auto;
    transform: none;
    bottom: 0;
  }
  .lightbox-nav--prev svg {
    left: 15px;
  }
  .lightbox-nav--next svg {
    right: 15px;
  }
}

// Close with text
.lightbox-close {
  display: flex;
  align-items: center;
  top: 10px;
  right: 10px;
  padding: 0.5em 1em;
  border: 2px solid;
  border-radius: 50px;
  background: #fff !important;
  color: $color-body-foreground !important;
  z-index: $lightbox-z-index + 3;
  font-size: rem(18px);

  .visuallyhidden {
    @include non-visuallyhidden;

    vertical-align: middle;
  }
  svg {
    display: inline-block;
    position: static;
    margin-left: 0.5em;
    vertical-align: middle;
    filter: none;
  }
  // Leave out focus - the button will get focus when the lightbox is opened
  // which makes it look off if the color is different. A focus outline is
  // displayed when using the keyboard and that's good enough.
  &:hover {
    color: $color-action !important;
  }

  // Close button for certificate preview - place near the right edge of the
  // preview window.
  .lightbox--certificate & {
    top: 2vh;
    @media screen and (min-aspect-ratio: 4 / 3) {
      right: calc(50% - 53vh);
    }
    @media screen and (max-aspect-ratio: 2 / 3) {
      top: auto;
      bottom: 10px;
    }
  }
}

// Close with cross icon only
/* .lightbox-close {
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  padding: 0;
  z-index: $lightbox-z-index + 3;

  svg {
    width: 16px;
    height: 16px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &:not([disabled]) {
    &:hover,
    &:focus {
      > span {
        background: #ddd;
      }
    }
  }
} */

/* -------------------- Default image -------------------- */
.lightbox-figure {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;

  img {
    max-height: 99vh;
    max-height: calc(100vh - 20px);
  }
}
.lightbox-caption {
  padding: 5px 5px 0;
  font-size: 14px;

  p {
    margin: 5px 0 0;

    &:first-child {
      margin-top: 0;
    }
  }
  a {
    color: inherit;
  }
}

/* -------------------- Animate opening -------------------- */
@keyframes modal-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes modal-fade-in-up {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes modal-fade-in-scale {
  from {
    transform: scale(0.7);
    transform-origin: 50% 50%;
    opacity: 0;
  }
  to {
    transform: scale(1);
    transform-origin: 50% 50%;
    opacity: 1;
  }
}

// Keep the animations fairly slow to make it clear for less savvy users that
// they're still on the same page.
.lightbox:not([aria-hidden='true']) {
  .lightbox-overlay {
    animation: modal-fade-in 0.6s 1 both;
  }
  .lightbox-content,
  .lightbox-close {
    animation: modal-fade-in-up 0.35s 0.2s 1 both;
  }
  .lightbox-nav-prev,
  .lightbox-nav-next {
    animation: modal-fade-in-scale 0.35s 0.2s 1 both;
  }
}
