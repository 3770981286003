/* -Intro image with content
---------------------------------------------------------------------------- */

// Keep duration in sync with Intro.js
$intro-animation-duration: 4000ms;
$intro-animation-pan: 2%;
$intro-animation-scale: 1.1;

$initial-intro-transform: translate(0, 0) scale(1);
@keyframes intro-top-left {
  0% {
    transform: $initial-intro-transform;
  }
  100% {
    transform: translate(-$intro-animation-pan, -$intro-animation-pan)
      scale($intro-animation-scale);
  }
}
@keyframes intro-top-right {
  0% {
    transform: $initial-intro-transform;
  }
  100% {
    transform: translate($intro-animation-pan, -$intro-animation-pan)
      scale($intro-animation-scale);
  }
}
@keyframes intro-bottom-right {
  0% {
    transform: $initial-intro-transform;
  }
  100% {
    transform: translate($intro-animation-pan, $intro-animation-pan)
      scale($intro-animation-scale);
  }
}
@keyframes intro-bottom-left {
  0% {
    transform: $initial-intro-transform;
  }
  100% {
    transform: translate(-$intro-animation-pan, $intro-animation-pan)
      scale($intro-animation-scale);
  }
}

// Must have a wrapping column flex container for IE11
// https://github.com/philipwalton/flexbugs#flexbug-3
.intro-container {
  display: flex;
  flex-direction: column;
}

.intro {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 40px;
  padding-top: 40px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  overflow: hidden;
  max-height: 860px;

  .start-page & {
    padding-bottom: 90px;
  }

  @media screen and (orientation: landscape) {
    min-height: 50vh;

    &.intro--small {
      min-height: 30vh;
    }
  }
  @media screen and (orientation: portrait) {
    min-height: 60vh;
  }
}

// Alignment intro content
.intro--vertical-top {
  justify-content: flex-start;
}
.intro--vertical-center {
  justify-content: center;
}
.intro--vertical-bottom {
  justify-content: flex-end;
}
.intro--horizontal-left {
  align-items: flex-start;
}
.intro--horizontal-center {
  align-items: center;
}
.intro--horizontal-right {
  align-items: flex-end;
}

.intro-bg {
  // Ensure text is readable if image or video fails to load
  background-color: #666;

  &,
  picture {
    // Need to override flickity base styles.
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  img,
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  // Ensure the first element is on top to prevent flashing when Flickity is
  // initiated, since it sets the first slide as selected. This will only
  // target non-Flickity picture since wrapping divs are added for the slider.
  > picture:first-child {
    z-index: 2;
  }
}
.intro-image-active {
  // Add buffer to keep the animation going as the active slide changes
  animation-duration: $intro-animation-duration + 2000ms;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;

  &[data-direction='top-left'] {
    animation-name: intro-top-left;
  }
  &[data-direction='top-right'] {
    animation-name: intro-top-right;
  }
  &[data-direction='bottom-right'] {
    animation-name: intro-bottom-right;
  }
  &[data-direction='bottom-left'] {
    animation-name: intro-bottom-left;
  }
}

.intro-content {
  @include page-margin;
  @include wrap-width-wide;

  position: relative;
  width: 100%;
  text-align: center;
  color: #fff;
  z-index: $z-intro;

  a {
    color: inherit;

    &:focus {
      outline-color: inherit;
    }
  }

  .intro--horizontal-left & {
    @include wrap-width-thin;

    text-align: left;
  }
  .intro--horizontal-right & {
    @include wrap-width-thin;

    text-align: right;
  }
}

.intro-content--standard {
  text-shadow: 0 0 20px rgba(0, 0, 0, 0.3);

  h1 {
    text-transform: uppercase;
    font-size: 28px;

    @include media-min(rem(600px)) {
      font-size: 40px;
    }
    @include media-min(rem(900px)) {
      font-size: 60px;
    }
  }
}

.intro-content--fact-box {
  width: auto;
}

.intro-content__inner--fact-box {
  padding: 25px;
  border-radius: 5px;
  text-align: left;
  background: rgba(50, 50, 50, 0.8);

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    letter-spacing: 0;
    font-family: $font-stack-main;
    color: $color-yellow;
    font-size: 20px;

    @include media-min(rem(600px)) {
      font-size: 34px;
    }
  }

  @include media-min(rem(640px)) {
    min-width: 480px;
  }
}
