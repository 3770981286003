/* -Form for adding a product to cart
---------------------------------------------------------------------------- */
// Can be for a regular product with displayed price and quantity input, or
// a flexible with a price input.

$add-to-cart-form-inline-breakpoint: rem(500px);

.add-to-cart-form {
  form {
    position: relative;
    width: 100%;
  }
  .form-errors-wrap,
  .form-message-wrap {
    position: absolute;
    width: 100%;
    bottom: 100%;
    left: 0;
  }
  .form-errors,
  .form-success {
    margin-bottom: 10px;
  }
  .form-field,
  .form-footer {
    margin: 0;
  }
  .form-error-small {
    display: none !important;
  }
  .field-error {
    position: absolute;
    top: 100%;
    left: 0;
  }
  .form-field--error .field-error {
    padding: 5px 10px;
    border-radius: 2px;
    background-color: $color-red;
    color: #fff;
    z-index: 1;
  }

  label {
    @include visuallyhidden;
  }
  // In case of wrapping
  input,
  button {
    margin-top: 5px;
  }
  button {
    @include button-red-dark;
  }
}

.add-to-cart-form--small {
  button {
    white-space: nowrap;
    font-size: inherit;

    @include media-min($add-to-cart-form-inline-breakpoint) {
      @include button-small;
    }
  }
  .form-field input {
    height: 2em;
  }
}

/* ------------------- Regular products ------------------- */
.add-to-cart__price {
  padding-right: 10px;
  margin-right: auto;

  .currency {
    @include lead-text;

    margin-bottom: 0;
  }
  .price {
    line-height: 1.2;
    font-weight: bold;
    font-size: 36px;

    @include media-min(rem(800px)) {
      font-size: 42px;
    }
  }
}

.add-to-cart-form--regular {
  .add-to-cart__fields {
    display: flex;
    align-items: center;
  }
  .form-field--quantity {
    input {
      width: 3.5em;
    }
  }

  @include media-max(rem(px($add-to-cart-form-inline-breakpoint) - 1px)) {
    .form-footer button {
      width: 100%;
    }
    .add-to-cart__fields {
      margin-bottom: 5px;
    }
  }
  @include media-min($add-to-cart-form-inline-breakpoint) {
    form {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
    .form-field--quantity {
      margin-right: 1em;
    }
    .add-to-cart__price {
      margin-right: 10px;
    }
  }
}

/* ------------------- Flexible products ------------------- */
.add-to-cart-form--flexible {
  display: flex;
  align-items: center;

  form {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .form-field--price {
    margin-right: 1em;
  }
}
