/* -Buttons
-----------------------------------------------------------------------------*/

/*---------- Mixins ----------*/
@mixin button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 9px 20px;
  border: 0;
  border-radius: $button-radius;
  font-weight: $font-weight-bold;
  font-size: rem($button-font-size);
  line-height: $button-height - em(18px, $button-font-size);
  text-decoration: none;
  text-align: center;
  text-shadow: none;
  vertical-align: middle;
  box-shadow: none;
  transition: background-color 0.15s ease-out;

  &:focus {
    outline: 1px dotted $color-body-foreground;
  }

  @include media-max(rem(400px)) {
    font-size: rem(16px);
  }

  .swish-logo-light-bg,
  .swish-logo-dark-bg {
    display: none;
  }
}

@mixin button-primary-base-colors {
  background-color: $color-red;
  color: #fff;
}
@mixin button-primary-active-colors {
  background-color: darken($color-red, 10%);
}
@mixin button-primary-colors {
  @include button-primary-base-colors;

  &:not([disabled]) {
    &:hover,
    &:focus {
      @include button-primary-active-colors;
    }
  }
}
@mixin button-primary {
  @include button;
  @include button-primary-colors;

  &[disabled] {
    opacity: 0.5;
  }

  .swish-logo-dark-bg {
    display: block;
  }
}

@mixin button-secondary-base-colors {
  background-color: rgba(0, 0, 0, 0.05);
  color: $color-body-foreground;
}
@mixin button-secondary-active-colors {
  background-color: rgba(0, 0, 0, 0.15);
}
@mixin button-secondary-colors {
  @include button-secondary-base-colors;

  &:not([disabled]) {
    &:hover,
    &:focus {
      @include button-secondary-active-colors;
    }
  }
}
@mixin button-secondary {
  @include button;
  @include button-secondary-colors;

  &[disabled] {
    opacity: 0.5;
  }
}

@mixin button-white-base-colors {
  background-color: #fff;
  color: $color-body-foreground;
}
@mixin button-white-active-colors {
  background-color: darken(#fff, 10%);
}
@mixin button-white-colors {
  @include button-white-base-colors;

  &:not([disabled]) {
    &:hover,
    &:focus {
      @include button-white-active-colors;
    }
  }
}
@mixin button-white {
  @include button;
  @include button-white-colors;

  &[disabled] {
    opacity: 0.5;
  }

  .swish-logo-light-bg {
    display: block;
  }
}

@mixin button-white-secondary-base-colors {
  background-color: #fff;
  color: $color-red;
}
@mixin button-white-secondary-active-colors {
  background-color: darken(#fff, 10%);
}
@mixin button-white-secondary-colors {
  @include button-white-secondary-base-colors;

  &:not([disabled]) {
    &:hover,
    &:focus {
      @include button-white-secondary-active-colors;
    }
  }
}
@mixin button-white-secondary {
  @include button;
  @include button-white-secondary-colors;

  &[disabled] {
    opacity: 0.5;
  }

  .swish-logo-light-bg {
    display: block;
  }
}

@mixin button-yellow-base-colors {
  background-color: $color-yellow;
  color: $color-body-foreground;
}
@mixin button-yellow-active-colors {
  background-color: darken($color-yellow, 10%);
}
@mixin button-yellow-colors {
  @include button-yellow-base-colors;

  &:not([disabled]) {
    &:hover,
    &:focus {
      @include button-yellow-active-colors;
    }
  }
}
@mixin button-yellow {
  @include button;
  @include button-yellow-colors;

  &[disabled] {
    opacity: 0.5;
  }
}

@mixin button-red-dark-base-colors {
  background-color: $color-red-dark;
  color: #fff;
}
@mixin button-red-dark-active-colors {
  background-color: darken($color-red-dark, 5%);
}
@mixin button-red-dark-colors {
  @include button-red-dark-base-colors;

  &:not([disabled]) {
    &:hover,
    &:focus {
      @include button-red-dark-active-colors;
    }
  }
}
@mixin button-red-dark {
  @include button;
  @include button-red-dark-colors;

  &[disabled] {
    opacity: 0.5;
  }
}

@mixin button-small {
  padding: 6px 10px 8px !important;
  line-height: $button-height-small - em(11px, $button-font-size-small) !important;
  font-size: rem($button-font-size-small) !important;
}

/*---------- Standard button classes ----------*/
.btn,
a.btn,
button,
input[type='submit'],
input[type='button'],
input[type='reset'] {
  @include button-primary;

  &[disabled] {
    cursor: default;
  }
  > span {
    vertical-align: middle;
  }
  .icon,
  .spinner {
    &:first-child {
      margin-right: 0.5em;
    }
    &:last-child {
      margin-left: 0.5em;
    }
    &:only-child {
      margin-left: 0;
      margin-right: 0;
      vertical-align: top;
    }
  }
}

a.btn-secondary,
.btn-secondary {
  @include button-secondary;
}

a.btn-white,
.btn-white {
  @include button-white;
}

a.btn-white-secondary,
.btn-white-secondary {
  @include button-white-secondary;
}

a.btn-yellow,
.btn-yellow {
  @include button-yellow;
}

.btn--small {
  @include button-small;
}

// ----- Special use case buttons -----

@mixin button-swish {
  @include button;

  &[disabled] {
    opacity: 0.5;
  }

  &::before {
    content: '';
    display: block;
    margin-right: 15px;
    width: 30px;
    height: 40px;
    background-image: url('../img/logo-swish.svg');
    background-repeat: no-repeat;
    background-size: contain;
  }

  @include media-max(rem(400px)) {
    &::before {
      margin-right: 10px;
      width: 20px;
      height: 30px;
    }
  }
}

.btn-swish {
  @include button-swish();
}

// Semantically a button but doesn't have any styles to indicate it
@mixin btn-neutral {
  height: auto;
  padding: 0;
  border: 0;
  border-radius: 0;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  background: transparent !important;
  color: inherit !important;
}
.btn-neutral {
  @include btn-neutral;

  .no-js & {
    cursor: default;
  }
}
